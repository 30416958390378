

export class Company {
    standNumber?: string;
    article_id?: number;
    branche?: string;
    name?: string;
    street?: string;
    zip?: string;
    phone?: string;
    year?: string;
    employees?: string;
    trainees?: string;
    internships?: string;
    legalform?: string;
    homepage?: string;
    email?: string;
    logo?: string;
    description?: string;
    video?: string;
    videoImage?: string;
    galarieImage?: string;

    links?: CompanyLinks[];
    members?: CompanyMember[];
    career?: CompanyCareer[];
    benefits?: any[];
    requirements?: any[];
}

export interface CompanyLinks {
    type: string;
    link: string;
}

export interface CompanyMember {
    name?: string;
    function?: string;
    phone?: string;
    mail?: string;
    image?: string;
}

export interface CompanyCareer {
    name: string;
    branche: string;
    type?: string;
    handicap?: string;
    barrier?: string;
    description?: string;
    condition?: string;
    benefit?: string;
    abschluss?: string;
}
