import { Company, CompanyCareer } from "../interfaces/company";
import { Button, Form, Select, Space, Switch, Typography } from "antd";
import React, {Fragment, useEffect, useState} from "react";
import { FilterModel } from "../models/filter";
import { Branch } from "../interfaces/branch";
import {useIsMobile} from "../hooks/mobile";
import Konva from "konva";
import Vector2d = Konva.Vector2d;

interface FilterProps {
    companies: Company[];
    branches: Branch[];
    filter: FilterModel;
    onChange: (filter: FilterModel | undefined) => void;
    scaleChange: (zoom: number) => void;
    scale: Vector2d;
}

export const Filter = ({companies, onChange, branches, filter, scale, scaleChange}: FilterProps) => {
    const isMobile = useIsMobile();
    const [ show, setShow ] = useState(!isMobile);

    const onCompanyChange = (value: string[], options: any) => {
        filter.companies = value.map((val) => val.replace("company_", ""));
        onChange(filter);
    }

    const onBranchesChange = (value: string[], options: any) => {
        filter.branches = value.map((val) => val.replace("branch_", ""));
        onChange(filter);
    }

    const onCareerChange = (value: string[], options: any) => {
        filter.careers = value.map((val) => val.replace("career_", ""));
        onChange(filter);
    }

    const onSwitchChange = (type: 'school' | 'educations' | 'studies', value: boolean) => {
        filter[type] = value;
        onChange(filter);
    }

    const getCareers = () => {
        const careers: CompanyCareer[] = [];
        for (const company of companies) {
            for (const career of company.career!) {
                const find = careers.find((car) =>
                    car.name.trim().toLowerCase() === career.name.trim().toLowerCase());

                if (!find) careers.push(career);
            }
        }
        return careers;
    }

    const resetFilter = () => {
        onChange(undefined);
    }

    const zoomIn = () => {
        scale.x += 0.1;
        if ( scale.x >= 1.9 ) scale.x = 1.9;
        scaleChange(scale.x);
    }

    const zoomOut = () => {
        scale.x -= 0.1;
        if ( scale.x <= 0.1 ) scale.x = 0.1;
        scaleChange(scale.x);
    }

    useEffect(() => {
        if ( isMobile ) {
            setShow(false);
        }
    }, []);

    return (
        <div className="filter-container">
            { !show && (
                <Fragment>
                    <div className="filter-body">
                        <Button onClick={() => setShow(!show)}>Filter Anzeigen</Button>
                    </div>
                    <div className="filter-body">
                        <Button onClick={zoomOut}>-</Button>
                        <Button onClick={zoomIn}>+</Button>
                    </div>
                </Fragment>
            )}
            { show && (
                <Fragment>
                    <div className="filter-body">
                        <Button
                            type="primary"
                            shape="circle"
                            icon={"x"}
                            style={{position: "absolute", top: 5, right: 5}}
                            onClick={() => setShow(!show)}
                        />
                        <Form layout="vertical">
                            <Space>
                                <Form.Item label="Ausbildung">
                                    <Switch value={filter.educations} onChange={(val) => onSwitchChange("educations", val)}/>
                                </Form.Item>

                                <Form.Item label="Studium">
                                    <Switch value={filter.studies} onChange={(val) => onSwitchChange("studies", val)}/>
                                </Form.Item>

                                <Form.Item label="Schulisch">
                                    <Switch value={filter.school} onChange={(val) => onSwitchChange("school", val)}/>
                                </Form.Item>
                            </Space>

                            <Form.Item label="Branche">
                                <Select
                                    id="select_branche"
                                    mode="multiple"
                                    allowClear
                                    size="large"
                                    style={{width: 350}}
                                    onChange={onBranchesChange}
                                >
                                    {
                                        branches.map((branch) => {
                                            return <Select.Option key={"branch_" + branch.id}>{branch.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item label="Angebote (m/w/d)">
                                <Select
                                    id="select_type"
                                    mode="multiple"
                                    allowClear
                                    size="large"
                                    style={{width: 350}}
                                    onChange={onCareerChange}
                                >
                                    {getCareers().map((career) => {
                                        return <Select.Option key={"career_" + career.name}>{career.name}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Form>
                        <Button onClick={() => resetFilter()}>
                            Suche Zurücksetzen
                        </Button>
                    </div>
                    <div className="filter-body">
                        <Typography.Title level={4}>Unternehmen A-Z</Typography.Title>
                        <Select
                            id="select_company"
                            mode="multiple"
                            allowClear
                            size={"large"}
                            style={{width: 350}}
                            onChange={onCompanyChange}
                        >
                            {companies.map((comp) => {
                                return <Select.Option key={"company_" + comp.name}>
                                    {comp.name}
                                </Select.Option>
                            })}
                        </Select>
                    </div>
                </Fragment>
            )}
        </div>
    );
}
