import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(false);

    const mediaQueryMobile = useMediaQuery({ query: '(max-width: 1000px)' });

    useEffect(() => {
        setIsMobile(mediaQueryMobile);
    }, [mediaQueryMobile]);

    return isMobile;
};
