import { Company } from "../interfaces/company";


export function PrepareCompany(slice: any) {
    let company = new Company();

    company.article_id = slice.article_id;

    if ( slice.value1 ) {
        const val1 = JSON.parse(slice.value1);
        company.standNumber = val1.standNumber;
        company.branche = val1.branche;

        if ( company.standNumber == "" || company.standNumber == " ") {
            return null;
        }
    }

    if ( slice.value2 ) {
        const val2 = JSON.parse(slice.value2);
        Object.assign(company, val2);
    }

    if ( slice.value3 ) {
        const values3 = JSON.parse(slice.value3);
        for ( const item of values3 ) {
            if ( !company.links ) company.links = [];
            company.links.push(item);
        }
    }

    if ( slice.value4 ) {
        const value4 = JSON.parse(slice.value4);
        for ( const item of value4 ) {
            if ( !company.members ) company.members = [];
            company.members.push(item);
        }
    }

    if ( slice.value5 ) {
        const value5 = JSON.parse(slice.value5);
        for ( const item of value5 ) {
            if ( !company.career ) company.career = [];
            company.career.push(item);
        }
    }

    if ( slice.value6 ) {
        //Todo
    }

    if ( slice.value7 ) {
        const value7 = JSON.parse(slice.value7);
        for (const item of value7) {
            if ( !company.benefits ) company.benefits = [];
            company.benefits.push(item);
        }
    }

    if ( slice.value8 ) {
        const value8 = JSON.parse(slice.value8);
        for (const item of value8) {
            if ( !company.requirements ) company.requirements = [];
            company.requirements.push(item);
        }
    }

    return company;
}