import { Rect, Text } from "react-konva";
import { useEffect, useState } from "react";
import { Company } from "../interfaces/company";
import {type} from "node:os";

interface ClickBoxProps {
    x: number;
    y: number;
    height?: number;
    width?: number;
    clicked: (stand: string | number) => void;
    company: (stand: string | number) => Company | undefined;
    hidden?: boolean;
    verticalText?: boolean;
    label?: string;
    stand: string | number;
}

export const ClickBox = ({x, y, height, width, company, clicked, stand, hidden, verticalText, label }: ClickBoxProps) => {
    const [ color, setColor ] = useState("lightblue");

    const refreshColors = () => {
        const companyData = company(stand);
        setColor((!companyData ? 'black' : 'lightblue'));
    }

    const highlight = () => {
        const companyData = company(stand);
        setColor((!companyData ? 'orange' : 'blue'));
    }

    const getColor = () => {
        const companyData = company(stand);
        if ( !companyData ) {
            return "black";
        }

        return color;
    }

    const getHeight = () => {
        if (!height) {
            return 50
        }

        return height
    }

    const getHeightText = () => {
        if (!height) {
            return y + 50 - 35
        }

        return y + height/2 - 7
    }

    const getWidth = () => {
        if (!width) {
            return 50
        }
        return width
    }

    const getWidthText = () => {
        if (!width) {
            if (typeof (stand) == "string") {
                return x + 50 - 35 - stand.length
            }

            if (stand < 10) {
                return x + 50 - 30
            }
            if (stand < 100) {
                return x + 50 - 35
            }
        }

        if (width) {
            if (typeof (stand) == "string") {
                return x + width/2 - stand.length * 5
            }
            if (stand < 10) {
                return x + width / 2 - 5
            } else if (stand < 100) {
                return x + width / 2 - 10
            }
        }

    }

    const getFontSize = () => {
        if (typeof (stand) == "string") {
            return 20 - 2 * stand.length
        }

        return 20
    }

    const getText = () => {
        if ( label && label.length !== 0) return label;

        if ( typeof(stand) === "string") {
            return stand;
        }

        return stand.toString();
    }

    const getTextProps = () => {
        const props = {
            x: getWidthText(),
            y: getHeightText(),
            height: getHeight(),
            fontSize: getFontSize(),
            text: getText(),
            fill: "white",
            onClick: () => clicked(stand),
            listening: false
        }

        if ( verticalText ) {
            const vTextProps = {
                rotation: 90,
                align: 'center',
                x: props.x! + 25,
                y: props.y! - 25,
            }
            Object.assign(props, vTextProps);
        }

        return props;
    }

    return (
        <>
            {!hidden &&
                <>
                    <Rect
                        x={x}
                        y={y}
                        width={getWidth()}
                        height={getHeight()}
                        fill={getColor()}
                        onClick={() => clicked(stand)}
                        onTap={() => clicked(stand)}
                        onMouseEnter={highlight}
                        onMouseLeave={refreshColors}
                    />
                    <Text
                        {...getTextProps()}
                    />
                </>
            }
        </>
    );
}
