import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from "./App";
import './assets/base.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/loge/zoom" element={<App logo={false} zoom />} />
                <Route path="/loge" element={<App logo={false} />} />
                <Route path="*" element={<App logo={true} />} />
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);
