import axios from "axios";


export class API {
    static URL: string = "https://jobklahr.de";


    static async request<T>(type: 'post' | 'get', url: string, data?: any): Promise<T | undefined> {
        return new Promise<T | undefined>( (resolve) => {
            axios({
                method: type,
                url: API.URL + url,
                data: data
            }).then( ( result ) => {
                if ( !result.data.success ) {
                    console.error(result.data);
                    resolve(undefined);
                }

                resolve(result.data.data as T);
            }).catch( ( error ) => {
                //console.log(error);
                resolve(undefined);
            });
        });
    }
}
